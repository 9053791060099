import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        cbApps: file(relativePath: { eq: "coolblue-apps.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cbRecruitmentSite: file(relativePath: { eq: "recruitment.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        veteraneninstituut: file(
          relativePath: { eq: "veteraneninstituut.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nijntje: file(relativePath: { eq: "nijntje.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Portfolio | Charlotte de Hilster</title>
        </Helmet>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 16px 16px;
            width: 100%;
            @media only screen and (max-width: 600px) {
              grid-template-columns: 100%;
              grid-template-rows: 1fr 1fr 1fr 1fr;
            }
          `}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            <Link to="/cb-app/">
              <Img className="test" fluid={data.cbApps.childImageSharp.fluid} />
              <p
                css={css`
                  position: absolute;
                  bottom: -28px;
                  background-color: white;
                  opacity: 0.8;
                  padding: 8px;
                  font-size: 12pt;
                  font-family: "Dosis", sans-serif;
                  color: #111111;
                `}
              >
                <span
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Coolblue &nbsp;
                </span>
                iOS &amp; Android App
              </p>
            </Link>
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Link to="/recruitment/">
              <Img fluid={data.cbRecruitmentSite.childImageSharp.fluid} />
              <p
                css={css`
                  position: absolute;
                  bottom: -28px;
                  background-color: white;
                  opacity: 0.8;
                  padding: 8px;
                  font-size: 12pt;
                  font-family: "Dosis", sans-serif;
                  color: #111111;
                `}
              >
                <span
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Coolblue &nbsp;
                </span>
                Recruitmentsite
              </p>
            </Link>
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Link to="/veteraneninstituut/">
              <Img fluid={data.veteraneninstituut.childImageSharp.fluid} />
              <p
                css={css`
                  position: absolute;
                  bottom: -28px;
                  background-color: white;
                  opacity: 0.8;
                  padding: 8px;
                  font-size: 12pt;
                  font-family: "Dosis", sans-serif;
                  color: #111111;
                `}
              >
                <span
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Veteraneninstituut &nbsp;
                </span>
                Responsive website
              </p>
            </Link>
          </div>
          <div
            css={css`
              position: relative;
            `}
          >
            <Link to="/nijntje-museum/">
              <Img fluid={data.nijntje.childImageSharp.fluid} />
              <p
                css={css`
                  position: absolute;
                  bottom: -28px;
                  background-color: white;
                  opacity: 0.8;
                  padding: 8px;
                  font-size: 12pt;
                  font-family: "Dosis", sans-serif;
                  color: #111111;
                `}
              >
                <span
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Nijntje museum &nbsp;
                </span>
                Responsive website
              </p>
            </Link>
          </div>
        </div>
      </Layout>
    )}
  />
);

export default IndexPage;
